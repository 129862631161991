.app__navbar-links>li {
    margin: 0 1rem;
    cursor: pointer;
}

.app__navbar-links>li:hover {
    color: var(--color-golden);
    transform: scale(0.9)
}

.app__navbar-login>a {
    margin: 0 1rem;
    transition: 0.3s ease;
}

.app__navbar-login>a:hover {
    border-bottom: 1px solid var(--color-golden);
}

.navbar__openMenu-links>li {
    margin: 2rem 0;
    color: var(--color-golden);
    font-size: 2rem;
    font-family: var(--font-base);
    text-align: center;
}

.navbar__openMenu-links>li:hover{
    color: var(--color-white);
}



/* * Generated by Animista on 2023-3-25 20:46:53 */

/* slide in from top entry */
.slide-in-blurred-top {
    -webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}



@-webkit-keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slide-in-blurred-top {
    0% {
        -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 0%;
        transform-origin: 50% 0%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
        transform: translateY(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}


/* slide out to top exit */
.slide-out-blurred-top {
    animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}


@keyframes slide-out-blurred-top {
    0% {
        transform: translateY(0) scaleY(1) scaleX(1);
        transform-origin: 50% 0%;
        filter: blur(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-1000px) scaleY(2) scaleX(0.2);
        transform-origin: 50% 0%;
        filter: blur(40px);
        opacity: 0;
    }
}

@media screen and (max-width: 420px) {
    .app__navbar-login>a{
        margin: 0 5px;
    }
}