.app__gallery-images_container::-webkit-scrollbar{
    display: none;
}


.app__gallery-images_card:hover img{
    opacity: 0.35;
}

.app__gallery-images_card:hover>svg{
    opacity: 1;
}